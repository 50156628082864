<template>
  <div
    style="
      overflow-x: scroll;
      overflow-y: scroll;
      margin-left: auto;
      margin: auto;
      max-height: 90vh;
    "
  >
    <div style="display: flex; flex-wrap: wrap; justify-content: center">
      <v-progress-linear
        style="margin: 4px 4px 0 4px"
        v-model="progress"
        v-show="showProgress"
      ></v-progress-linear>
      <!-- <button class="btn btn-success" @click="newReport()" style="margin: 2px">
      Проверка
    </button> -->
      <div>
        <div>
          <button
            class="btn btn-success"
            @click="report()"
            style="margin: 2px"
            title="Перед загрузкой данного отчета необходимо сформировать отчет на 'Питание'(или Питание конкретной смены) в первом блоке"
          >
            Показать\скрыть отчет питания
          </button>
        </div>

        <br />
        <!-- <div>
        <button
          class="btn btn-success"
          @click="googleSheet()"
          disabled
          style="margin: 2px"
        >
          Скачать форму
        </button>
      </div> -->

        <!-- overflow-x: scroll; -->
        <div style="display: flex; flex-wrap: wrap; justify-content: center">
          <table v-if="showTable" border="1">
            <tr>
              <th colspan="3">1-4 классы</th>
              <th colspan="3">5-9 классы</th>
              <th colspan="3">10-11 классы</th>
            </tr>
            <tr>
              <th>Вс.:</th>
              <th>Пит.:</th>
              <th>%:</th>
              <th>Вс.:</th>
              <th>Пит.:</th>
              <th>%:</th>
              <th>Вс.:</th>
              <th>Пит.:</th>
              <th>%:</th>
            </tr>
            <tr>
              <td>{{ firstZveno.studentCount }}</td>
              <td>{{ firstZveno.count }}</td>
              <td>
                {{
                  ((firstZveno.count / firstZveno.studentCount) * 100).toFixed(
                    1
                  )
                }}
              </td>
              <td>{{ twoZveno.studentCount }}</td>
              <td>{{ twoZveno.count }}</td>
              <td>
                {{
                  ((twoZveno.count / twoZveno.studentCount) * 100).toFixed(1)
                }}
              </td>
              <td>{{ treeZveno.studentCount }}</td>
              <td>{{ treeZveno.count }}</td>
              <td>
                {{
                  ((treeZveno.count / treeZveno.studentCount) * 100).toFixed(1)
                }}
              </td>
            </tr>
          </table>
        </div>
        <div v-if="showTable" style="margin: auto; width: 300px">
          <div
            v-if="freeEatingProvider.length > 0 && showTable"
            style="color: red; font-style: bold"
          >
            ВНИМАНИЕ! Есть порции за счет поставщика:
            <b
              >{{
                freeEatingProvider.reduce(function (sum, elem) {
                  return sum + elem.count;
                }, 0)
              }}
              шт.</b
            >

            <div v-for="(item, index) in freeEatingProvider" :key="index">
              <div>{{ item.className }}: {{ item.count }}</div>
            </div>
          </div>
          Питание без категорий:
          <ul>
            <li>1-4 классы: {{ emptyCat[0] }}</li>
            <li>5-9 классы: {{ emptyCat[1] }}</li>
            <li>10-11 классы: {{ emptyCat[2] }}</li>
          </ul>
        </div>
        <div v-if="showTable" style="margin: auto">
          <table border="1">
            <tr v-for="(paral, index) in parallel" :key="index">
              <td v-if="index === 0">Всего</td>
              <td v-else>{{ index }} параллель</td>
              <td>{{ paral }}</td>
            </tr>
          </table>
        </div>

        <br />
      </div>
      <div v-if="showTable">
        <table border="1" style="margin: 10px; width: auto">
          <tr>
            <td colspan="2">
              <b>ПИТАНИЕ УЧАЩИХСЯ 1-4 классов, НЕ ИМЕЮЩИХ ЛЬГОТНУЮ КАТЕГОРИЮ</b>
            </td>
          </tr>
          <tr>
            <td>Питание учащихся 1-4 классов (завтрак)</td>
            <td>{{ newArrOrder[0] }}</td>
          </tr>
          <tr>
            <td>Питание учащихся 1-4 классов (обед)</td>
            <td>{{ newArrOrder[1] }}</td>
          </tr>
          <!-- 1- 4 ОВЗ -->
          <tr>
            <td colspan="2"><b>ПИТАНИЕ УЧАЩИХСЯ с ОВЗ 1-4 классов</b></td>
          </tr>
          <tr>
            <td>Первое питание для учащихся с ОВЗ в 1 смене (завтрак)</td>
            <td>{{ newArrOrder[2] }}</td>
          </tr>
          <tr>
            <td>Второе питание для учащихся с ОВЗ в 1 смене (обед)</td>
            <td>{{ newArrOrder[2] }}</td>
          </tr>
          <tr>
            <td>Первое питание для учащихся с ОВЗ во 2 смене (обед)</td>
            <td>{{ newArrOrder[3] }}</td>
          </tr>
          <tr>
            <td>Второе питание для учащихся с ОВЗ во 2 смене (полдник)</td>
            <td>{{ newArrOrder[3] }}</td>
          </tr>
          <!-- 1-4 инвалиды без овз -->
          <tr>
            <td colspan="2">
              <b>ПИТАНИЕ ДЕТЕЙ-ИНВАЛИДОВ без статуса ОВЗ 1-4 классов</b>
            </td>
          </tr>
          <tr>
            <td>
              Первое питание для детей-инвалидов без ОВЗ в 1 смене (завтрак)
            </td>
            <td>{{ newArrOrder[4] }}</td>
          </tr>
          <tr>
            <td>Второе питание для детей-инвалидов без ОВЗ в 1 смене (обед)</td>
            <td>{{ newArrOrder[4] }}</td>
          </tr>
          <tr>
            <td>
              Первое питание для детей-инвалидов без ОВЗ во 2 смене (обед)
            </td>
            <td>{{ newArrOrder[5] }}</td>
          </tr>
          <tr>
            <td>
              Второе питание для детей-инвалидов без ОВЗ во 2 смене (полдник)
            </td>
            <td>{{ newArrOrder[5] }}</td>
          </tr>

          <!-- мд и мо 5-11 -->

          <tr>
            <td colspan="2">
              <b
                >ПИТАНИЕ УЧАЩИХСЯ из МНОГОДЕТНЫХ МАЛООБЕСПЕЧЕННЫХ семей 5-11
              </b>
            </td>
          </tr>
          <tr>
            <td>
              Питание учащихся из многодетных малообеспеченных семей в 1 смене
              (завтрак)
            </td>
            <td>{{ newArrOrder[6] }}</td>
          </tr>
          <tr>
            <td>
              Питание учащихся из многодетных малообеспеченных семей во 2 смене
              (обед)
            </td>
            <td>{{ newArrOrder[7] }}</td>
          </tr>

          <!-- МО 5-11 -->

          <tr>
            <td colspan="2">
              <b>ПИТАНИЕ УЧАЩИХСЯ из МАЛООБЕСПЕЧЕННЫХ семей 5-11 классов </b>
            </td>
          </tr>
          <tr>
            <td>
              (СВО+МО+СОП) Питание учащихся из малообеспеченных семей в 1 смене
              (завтрак)
            </td>
            <td>{{ newArrOrder[8] }}</td>
          </tr>
          <tr>
            <td>
              (СВО+МО+СОП) Питание учащихся из малообеспеченных семей во 2 смене
              (обед)
            </td>
            <td>{{ newArrOrder[9] }}</td>
          </tr>

          <!-- овз 5-11 -->
          <tr>
            <td colspan="2">
              <b>ПИТАНИЕ УЧАЩИХСЯ с ОВЗ 5-11 классов </b>
            </td>
          </tr>
          <tr>
            <td>Первое питание для учащихся с ОВЗ в 1 смене (завтрак)</td>
            <td>{{ newArrOrder[10] }}</td>
          </tr>
          <tr>
            <td>Второе питание для учащихся с ОВЗ в 1 смене (обед)</td>
            <td>{{ newArrOrder[10] }}</td>
          </tr>
          <tr>
            <td>Первое питание для учащихся с ОВЗ во 2 смене (обед)</td>
            <td>{{ newArrOrder[11] }}</td>
          </tr>
          <tr>
            <td>Второе питание для учащихся с ОВЗ во 2 смене (полдник)</td>
            <td>{{ newArrOrder[11] }}</td>
          </tr>

          <!-- инвалиды 5-11 -->
          <tr>
            <td colspan="2">
              <b>ПИТАНИЕ ДЕТЕЙ-ИНВАЛИДОВ без статуса ОВЗ 5-11 классов </b>
            </td>
          </tr>
          <tr>
            <td>
              Первое питание для детей-инвалидов без ОВЗ в 1 смене (завтрак)
            </td>
            <td>{{ newArrOrder[12] }}</td>
          </tr>
          <tr>
            <td>Второе питание для детей-инвалидов без ОВЗ в 1 смене (обед)</td>
            <td>{{ newArrOrder[12] }}</td>
          </tr>
          <tr>
            <td>
              Первое питание для детей-инвалидов без ОВЗ во 2 смене (обед)
            </td>
            <td>{{ newArrOrder[13] }}</td>
          </tr>
          <tr>
            <td>
              Второе питание для детей-инвалидов без ОВЗ во 2 смене (полдник)
            </td>
            <td>{{ newArrOrder[13] }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
//import exceljs from "exceljs/dist/es5/exceljs.browser";
//import * as XLSX from "xlsx";
export default {
  props: ["classListAll", "sCategory", "studentsList"],
  data() {
    return {
      emptyCat: [0, 0, 0],
      progress: 0,
      showProgress: false,
      showTable: false,
      cellsInGoogleSheet: [0, 0, 0, 0, 0, 0, 0, 0],
      firstClassCountGoogleSheet: [0, 0, 0, 0],
      firstZveno: { studentCount: 0, count: 0 },
      twoZveno: { studentCount: 0, count: 0 },
      treeZveno: { studentCount: 0, count: 0 },
      parallel: [],
      newArrOrder: [],
      freeEatingProvider: [],
    };
  },
  methods: {
    async report() {
      this.newReport();
      this.cellsInGoogleSheet = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      this.firstClassCountGoogleSheet = [0, 0, 0, 0];

      console.log(this.classListAll, this.sCategory);
      this.countDayWeek();
      let keyMap = "";
      console.log(this.classListAll, this.sCategory);
      for (let i = 0; i < this.classListAll.length - 1; i++) {
        if (
          this.classListAll[i].className.length === 3 ||
          (this.classListAll[i].className[0] != "1" &&
            this.classListAll[i].className[0] != "2" &&
            this.classListAll[i].className[0] != "3" &&
            this.classListAll[i].className[0] != "4")
        ) {
          console.log("Обработал ", this.classListAll[i].className);
          keyMap = Object.entries(this.classListAll[i]);

          //keyMap.length = keyMap.length - (keyMap.length - this.sCategory.length);

          for (let j = 0; j < keyMap.length; j++) {
            console.log(keyMap[j][0], keyMap[j][1]);
            await this.countCell(
              this.classListAll[i].shift,
              keyMap[j][0],
              this.classListAll[i].dayWeek,
              keyMap[j][1]
            );
          }
        } else {
          keyMap = Object.entries(this.classListAll[i]);

          //keyMap.length = keyMap.length - (keyMap.length - this.sCategory.length);

          for (let j = 0; j < keyMap.length; j++) {
            await this.countCellFirstClass(
              this.classListAll[i].shift,
              keyMap[j][0],
              keyMap[j][1]
            );
          }
        }
      }

      console.log(this.cellsInGoogleSheet);
      console.log(this.classListAll, this.sCategory);
      this.showTable = !this.showTable;

      this.countEveryDayReport();
    },
    async googleSheet() {
      this.showProgress = true;
      this.progress = 10;
      const { GoogleSpreadsheet } = require("google-spreadsheet");

      const doc = new GoogleSpreadsheet("16C194Fo_CFpGWq1VhT-lrJJkYZWudwyE");
      this.progress = 20;
      await doc.useServiceAccountAuth({
        // env var values are copied from service account credentials generated by google
        // see "Authentication" section in docs for more info
        client_email: "haano-d41@sacred-result-364416.iam.gserviceaccount.com",
        private_key:
          "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCQShOo8mFbc5z3\ne4P9tLJKkdBVY8Zrcs8A/ZhWRahDXCWmREHcXUbEwDBAjlZedhLOz571rc3/ApbW\n6/gW0vnSFMJjIkejiNCESleu6vXye1+tzXgjph9atGnBrZJqif7IHbBfASXdiywf\nOE/LMpR8nklns+2XqVSD7ZUxukuN9Z9O+gJDPf5Ejds6VyilbxEewZagYP2QAK1B\nrwqa6ub/pM7P7J9mEx4Vi/tfAY0lrnH4dcG4TX489GzG9Vz9tYpOdLkq4osvs/8f\nfgXrvOllX8suKi2CQXMBcRwbaAYSh2zlF5JUHxtABagoHZ50A2g1Hq5HSKVps3iW\n2C9TybQRAgMBAAECggEABxSDdh5SoFSs0UivzN1hhEmMZ1bqhRo7RclWgMvmj6Dw\nE1e1GErsGTp0WQPtrAaaMf4RHEK84XRZ53jpEbb6MuX5izjC5Xzl/R/SwU6z5oP0\nSXMpdS0GdoJ00AqkLR3RW+DNEemyAcwoTEM3SHlrvNkIMWesUxf9gYMWd5FfJLuO\nX6lLjvTd/iWOKsFqEJiVDRIJBzWGGqTMV0kb81E4/Swm9sv5KIhdBdAlGt1sPuLK\nnA90IZKJ6cFjCBpJARjcmDIOxUppK4vMC9Qt7YrtGK9HV3yD5ZFPPg2+aTIeSCI1\n1t5hjyLnK/CHsdb6o0lNpapLEPxgg3ES/XSfYB/QWQKBgQDDJ2qUDW33L5z9wfSS\nNiNUzT4AeznNvjy8b6f6v+8l8PUtNSa+4Wai2wcX7xU9M9tTKg9umQlUC8tsH3Gs\nEjmE/dUJq089Tq81JEF4NzSFOOzYmlbtsNoQmizwixNNbzcCmQqUMmRNE1AghbTB\nVtG2DEUCADtfLPw58AZSti13bQKBgQC9Rs41f1JOnrw4ctUughWeSA9/6mLoAFe+\ncxnsTcnG4+ZZf69x57O4mqgbfg0MUjmIsbW+OucRDYoBnEcrLX0Uh0ZG/NI5Ayi5\nSzxp55JpHRhQW9+cXeRoW7Kt9qLAEwdWxiYkBdt48Touxx7pU2JQZHriKXTGkDqZ\nqmShDPbUtQKBgHEUqu4lJf4gidCEjcoXMrvVvcKbpK/yNtFdxbFHfDS4/eh3Lv9W\nLpnZXq/gqqbvIHx9F7oXVU9tEi+RvNVfLhNA3iwfJug4as/ZNVKKcpVrmdoc/Jf4\nbTNHkxjDDalF81M/COE8neAHe9uMfmO1ggkB7Q6uwbhxoamUyqUsyQW1AoGAKDGK\nBqhD9TgWJjKUfKzZJGSPDg3a9QNlnqbYoWqfZ6Hrv6OywRCABLqq6jOpV616VEHQ\no1XkzaU/Yjoqjsu5HQvg66AykDLQihClAqRPXgz0pdPst0rk/xhB6FlQlFM2IsIv\nRUUNJzCqt1ZHhSrU3NeJlM9S4fusxgb+GYkkPWUCgYAUua1bBYDuPtfvBtmwWitu\nkP6HU6XplAmDa5kRQ79FRevQzGvDG/QaIqgAR+Iztdn/hSrfVB3Z1lmUcFOCH5SN\n4JNC4kTRMNrqUhRa4OZ/8pKgUxQ5RCJq+7sFgU6Sz33/gR++Jg61y+b2eYjWtcxf\neMeluJol3/um8m+GRAHG/g==\n-----END PRIVATE KEY-----\n",
      });
      this.progress = 40;

      console.log("Успешно");
      await doc.loadInfo(); // loads sheets
      this.progress = 50;
      console.log("Успешно");
      const sheet = doc.sheetsByIndex[0]; // the first sheet
      this.progress = 60;
      // await sheet.loadCells("A1:D5");
      // const cellA1 = sheet.getCell(0, 0);
      // const cellC3 = sheet.getCellByA1("C3");
      await doc.loadInfo(); // loads document properties and worksheets
      this.progress = 65;
      console.log(doc.title);
      await doc.updateProperties({ title: "Отчет" });
      this.progress = 70;
      console.log("3 проверка");
      await sheet.loadCells("C12:C32"); // loads range of cells into local cache - DOES NOT RETURN THE CELLS
      this.progress = 80; // total cells, loaded, how many non-empty
      let cells = [];
      let int = 0;
      let str = "";
      for (let i = 12; i < 33; i++) {
        str = "C" + i;
        cells[i] = sheet.getCellByA1(str);
        cells[i].value = this.cellsInGoogleSheet[int];
        int += 1;
      }

      // access everything about the cell

      // update the cell contents and formatting
      // a1.value = 123.456;
      // c6.formula = "=A1";
      // a1.textFormat = { bold: true };
      // c6.note = "This is a note!";

      // c12.value = 1;
      // c13.value = 2;
      // c14.value = 5;
      // c15.value = 6;

      await sheet.saveUpdatedCells();
      this.progress = 100;

      let tab = window.open(
        "https://docs.google.com/spreadsheets/d/1lZ0BYDIC0F8D4Fra06p80hxS3Yofh65buI5Bnq5GQds/export?exportFormat=xlsx"
      );
      console.log(tab);

      this.showProgress = false;
    },

    newReport() {
      this.freeEatingProvider = [];
      let arrReport = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let set;
      let tempCount;
      this.emptyCat = [0, 0, 0];
      set = new Set();
      console.log(this.classListAll, this.sCategory, this.studentsList);
      for (let j = 0; j < this.classListAll.length; j++) {
        if (this.classListAll[j].level === 1) {
          this.emptyCat[0] += this.classListAll[j].countEmptyCat;
        }
        if (this.classListAll[j].level === 2) {
          this.emptyCat[1] += this.classListAll[j].countEmptyCat;
        }
        if (this.classListAll[j].level === 3) {
          this.emptyCat[2] += this.classListAll[j].countEmptyCat;
        }
        tempCount = 0;
        let keys = Object.keys(this.classListAll[j]);
        for (let i = 0; i < this.sCategory.length; i++) {
          for (let k = 0; k < keys.length; k++) {
            if (
              this.sCategory[i].id === keys[k] &&
              this.sCategory[i].sCategory === "Счет поставщика" &&
              this.classListAll[j].level &&
              this.classListAll[j][keys[k]] > 0
            ) {
              this.freeEatingProvider.push({
                className: this.classListAll[j].className,
                count: this.classListAll[j][keys[k]],
              });
              console.log("СЛОЖИЛ");
            }
            if (
              this.sCategory[i].id === keys[k] &&
              this.classListAll[j].level === 1
            ) {
              if (
                this.sCategory[i].sCategory === "ОВЗ" ||
                this.sCategory[i].sCategory === "Инвалиды"
              ) {
                tempCount += this.classListAll[j][keys[k]];
                if (this.sCategory[i].sCategory === "ОВЗ") {
                  if (this.classListAll[j].shift === "1 смена")
                    arrReport[2] += this.classListAll[j][keys[k]];
                  if (this.classListAll[j].shift === "2 смена")
                    arrReport[3] += this.classListAll[j][keys[k]];
                }
                if (this.sCategory[i].sCategory === "Инвалиды") {
                  if (this.classListAll[j].shift === "1 смена")
                    arrReport[4] += this.classListAll[j][keys[k]];
                  if (this.classListAll[j].shift === "2 смена")
                    arrReport[5] += this.classListAll[j][keys[k]];
                }
              }
            }

            if (
              this.sCategory[i].id === keys[k] &&
              (this.classListAll[j].level === 3 ||
                this.classListAll[j].level === 2)
            ) {
              if (this.sCategory[i].sCategory === "МД и МО") {
                if (this.classListAll[j].shift === "1 смена")
                  arrReport[6] += this.classListAll[j][keys[k]];
                if (this.classListAll[j].shift === "2 смена")
                  arrReport[7] += this.classListAll[j][keys[k]];
              }
              if (this.sCategory[i].sCategory === "МО") {
                if (this.classListAll[j].shift === "1 смена")
                  arrReport[8] += this.classListAll[j][keys[k]];
                if (this.classListAll[j].shift === "2 смена")
                  arrReport[9] += this.classListAll[j][keys[k]];
              }
              if (this.sCategory[i].sCategory === "СВО") {
                if (this.classListAll[j].shift === "1 смена")
                  arrReport[8] += this.classListAll[j][keys[k]];
                if (this.classListAll[j].shift === "2 смена")
                  arrReport[9] += this.classListAll[j][keys[k]];
              }
              if (this.sCategory[i].sCategory === "СОП") {
                if (this.classListAll[j].shift === "1 смена")
                  arrReport[8] += this.classListAll[j][keys[k]];
                if (this.classListAll[j].shift === "2 смена")
                  arrReport[9] += this.classListAll[j][keys[k]];
              }
              if (this.sCategory[i].sCategory === "ОВЗ") {
                if (this.classListAll[j].shift === "1 смена")
                  arrReport[10] += this.classListAll[j][keys[k]];
                if (this.classListAll[j].shift === "2 смена")
                  arrReport[11] += this.classListAll[j][keys[k]];
              }
              if (this.sCategory[i].sCategory === "Инвалиды") {
                if (this.classListAll[j].shift === "1 смена")
                  arrReport[12] += this.classListAll[j][keys[k]];
                if (this.classListAll[j].shift === "2 смена")
                  arrReport[13] += this.classListAll[j][keys[k]];
              }
            }
          }
        }
        if (this.classListAll[j].level === 1) {
          if (this.classListAll[j].shift === "1 смена")
            arrReport[0] += this.classListAll[j].count - tempCount;
          if (this.classListAll[j].shift === "2 смена")
            arrReport[1] += this.classListAll[j].count - tempCount;
        }
      }
      console.log(arrReport, set);
      this.newArrOrder = arrReport;
    },

    countEveryDayReport() {
      this.firstZveno = { studentCount: 0, count: 0 };
      this.twoZveno = { studentCount: 0, count: 0 };
      this.treeZveno = { studentCount: 0, count: 0 };

      for (let j = 0; j < this.classListAll.length; j++) {
        this.$set(this.classListAll[j], "studentCount", 0);
      }
      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.classListAll.length; j++) {
          if (this.studentsList[i].classID === this.classListAll[j].classID) {
            this.$set(
              this.classListAll[j],
              "studentCount",
              this.classListAll[j].studentCount + 1
            );
          }
        }
      }

      for (let j = 0; j < this.classListAll.length; j++) {
        if (this.classListAll[j].level === 1) {
          this.$set(
            this.firstZveno,
            "studentCount",
            this.classListAll[j].studentCount + this.firstZveno.studentCount
          );
          this.$set(
            this.firstZveno,
            "count",
            this.classListAll[j].count + this.firstZveno.count
          );
        }
        if (this.classListAll[j].level === 2) {
          this.$set(
            this.twoZveno,
            "studentCount",
            this.classListAll[j].studentCount + this.twoZveno.studentCount
          );
          this.$set(
            this.twoZveno,
            "count",
            this.classListAll[j].count + this.twoZveno.count
          );
        }
        if (this.classListAll[j].level === 3) {
          this.$set(
            this.treeZveno,
            "studentCount",
            this.classListAll[j].studentCount + this.treeZveno.studentCount
          );
          this.$set(
            this.treeZveno,
            "count",
            this.classListAll[j].count + this.treeZveno.count
          );
        }
      }

      for (let i = 0; i < 12; i++) {
        this.parallel[i] = 0;
      }
      for (let j = 0; j < this.classListAll.length - 1; j++) {
        this.parallel[this.classListAll[j].parallel] +=
          this.classListAll[j].count;
        this.parallel[0] += this.classListAll[j].count;
      }

      console.log(
        this.firstZveno,
        this.twoZveno,
        this.treeZveno,
        this.parallel
      );
    },

    countCell(shift, idCategory, dayWeek, count) {
      if (shift == "1 смена") {
        if (dayWeek == 5) {
          for (let i = 0; i < this.sCategory.length; i++) {
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МО"
            ) {
              this.cellsInGoogleSheet[0] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МД и МО"
            ) {
              this.cellsInGoogleSheet[1] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "СОП"
            ) {
              this.cellsInGoogleSheet[3] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "ОВЗ"
            ) {
              this.cellsInGoogleSheet[3] += count;
              continue;
            }
          }
        }
        if (dayWeek == 6) {
          for (let i = 0; i < this.sCategory.length; i++) {
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МО"
            ) {
              this.cellsInGoogleSheet[4] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МД и МО"
            ) {
              this.cellsInGoogleSheet[5] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "СОП"
            ) {
              this.cellsInGoogleSheet[6] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "ОВЗ"
            ) {
              this.cellsInGoogleSheet[7] += count;
              continue;
            }
          }
        }
      }
      if (shift == "2 смена") {
        if (dayWeek == 5) {
          for (let i = 0; i < this.sCategory.length; i++) {
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МО"
            ) {
              this.cellsInGoogleSheet[8] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МД и МО"
            ) {
              this.cellsInGoogleSheet[9] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "СОП"
            ) {
              this.cellsInGoogleSheet[10] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "ОВЗ"
            ) {
              this.cellsInGoogleSheet[11] += count;
              continue;
            }
          }
        }
        if (dayWeek == 6) {
          for (let i = 0; i < this.sCategory.length; i++) {
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МО"
            ) {
              this.cellsInGoogleSheet[12] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "МД и МО"
            ) {
              this.cellsInGoogleSheet[13] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "СОП"
            ) {
              this.cellsInGoogleSheet[14] += count;
              continue;
            }
            if (
              idCategory == this.sCategory[i].id &&
              this.sCategory[i].sCategory == "ОВЗ"
            ) {
              this.cellsInGoogleSheet[15] += count;
              continue;
            }
          }
        }
      }
    },
    countDayWeek() {
      for (let i = 0; i < this.classListAll.length; i++) {
        if (this.classListAll[i].className === "7д2") {
          this.classListAll[i].dayWeek = 5;
          console.log("7Д2");
          this.$set(this.classListAll[i], "zveno", 2);
          this.$set(this.classListAll[i], "parallel", 7);
          continue;
        }
        if (this.classListAll[i].className.length == 3) {
          //this.classListAll[i].dayWeek = 6;
          this.$set(this.classListAll[i], "dayWeek", 6);
          this.$set(this.classListAll[i], "zveno", 3);
          if (
            this.classListAll[i].className[0] +
              this.classListAll[i].className[1] ===
            "10"
          ) {
            this.$set(this.classListAll[i], "parallel", 10);
          } else {
            this.$set(this.classListAll[i], "parallel", 11);
          }
          continue;
        }

        if (this.classListAll[i].className[0] == "1") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 1);
          this.$set(this.classListAll[i], "parallel", 1);
          continue;
        }
        if (this.classListAll[i].className[0] == "2") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 1);
          this.$set(this.classListAll[i], "parallel", 2);
          continue;
        }
        if (this.classListAll[i].className[0] == "3") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 1);
          this.$set(this.classListAll[i], "parallel", 3);
          continue;
        }
        if (this.classListAll[i].className[0] == "4") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 1);
          this.$set(this.classListAll[i], "parallel", 4);
          continue;
        }
        if (this.classListAll[i].className[0] == "5") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 2);
          this.$set(this.classListAll[i], "parallel", 5);
          continue;
        }
        if (this.classListAll[i].className[0] == "6") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 2);
          this.$set(this.classListAll[i], "parallel", 6);
        }
        if (this.classListAll[i].className[0] == "7") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 2);
          this.$set(this.classListAll[i], "parallel", 7);
          continue;
        }
        if (this.classListAll[i].className[0] == "8") {
          this.classListAll[i].dayWeek = 5;
          this.$set(this.classListAll[i], "zveno", 2);
          this.$set(this.classListAll[i], "parallel", 8);
          continue;
        }
        if (this.classListAll[i].className[0] == "9") {
          this.classListAll[i].dayWeek = 6;
          this.$set(this.classListAll[i], "zveno", 2);
          this.$set(this.classListAll[i], "parallel", 9);
          continue;
        }
      }
    },

    countCellFirstClass(shift, idCategory, count) {
      if (shift === "1 смена") {
        for (let i = 0; i < this.sCategory.length; i++) {
          if (
            idCategory == this.sCategory[i].id &&
            this.sCategory[i].sCategory == "ОВЗ"
          ) {
            this.firstClassCountGoogleSheet[2] += count;
            this.firstClassCountGoogleSheet[4] += count;
            continue;
          }
          if (idCategory == this.sCategory[i].id) {
            this.firstClassCountGoogleSheet[0] += count;
          }
        }
      } else {
        for (let i = 0; i < this.sCategory.length; i++) {
          if (
            idCategory == this.sCategory[i].id &&
            this.sCategory[i].sCategory == "ОВЗ"
          ) {
            this.firstClassCountGoogleSheet[3] += count;
            this.firstClassCountGoogleSheet[5] += count;
            continue;
          }
          if (idCategory == this.sCategory[i].id) {
            this.firstClassCountGoogleSheet[1] += count;
          }
        }
      }
    },
  },
  mounted() {
    this.$emit("ready", true);
  },
};
</script>

<style type="text/css" scoped>
BODY {
  background: white; /* Цвет фона веб-страницы */
}
TABLE {
  width: 300px; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
  border: 2px solid white; /* Прячем рамку вокруг таблицы */
  font-size: 12px;
}
TD,
TH {
  padding: 4px; /* Поля вокруг содержимого таблицы */
  border: 2px solid rgb(0, 0, 0); /* Параметры рамки */
  text-align: left; /* Выравнивание по левому краю */
}

#rotateText {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: left;
  max-height: 150px;
}
</style>
